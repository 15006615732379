import { useEffect, useRef, useState } from "react";
import styles from "./index.module.css";
import SideBar from "../common/SideBar";
import { getAssociatedBusiness } from "../../apiServices/overviewService";
import { useSelector } from "react-redux";
import { dateConverter } from "../../utilServices/utils";
import contentPieces from "./../../assets/overview/contentPieces.png";
import views from "./../../assets/overview/views.png";
import likes from "./../../assets/overview/likes.png";
import comments from "./../../assets/overview/comments.png";
import autoDMSent from "./../../assets/overview/autoDMSent.png";
import engagementRate from "./../../assets/overview/engagementRate.png";
import productsSold from "./../../assets/overview/productsSold.png";
import productsClicked from "./../../assets/overview/productsClicked.png";
import websiteVisits from "./../../assets/overview/websiteVisits.png";
import { dateFormats } from "../../enums";
import OverviewTable from "../common/Tables/overviewTable";
import Logout from "./../common/Logout";
import NameInitials from "../common/NameInitials/index";
import {
    overviewTableHeaders,
    overviewSubTableHeader,
    overviewSortDropDownElements,
} from "../../utilServices/tableUtils";
import SortDataModal, { overviewDataKeyMapping } from "../common/sortDataModal";
import { getShortNumber } from "../../utilServices/utils";
import Shimmer from "../common/Shimmer";
import { useDispatch } from "react-redux";
import { clearOverviewState } from "../../redux/slice/overviewSlice";
import sidebarExpand from "./../../assets/sideBar/sidebarExpand.png";
import { setIsCollapsed } from "../../redux/slice/sidebarSlice";
import RoiPerCampaign from "./roiPerCampaign/index";
import OverAllPerformance from "./overallPerformance/index";
import CampaignPerformance from "./campaignPerformance/index";

const Index = () => {
    const {
        associatedBusinesses,
        engagements,
        stats,
        creators,
        overallOverviewData,
        overallSortKey,
        creatorWiseSalesData,
    } = useSelector((state: any) => state.root.overview);
    const { isCollapsed } = useSelector((state: any) => state.root.sidebar);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const [isSortOpen, setIsSortOpen] = useState(false);
    const dispatch = useDispatch();

    const engagementsArr = [
        {
            title: "Content Pieces",
            value: getShortNumber(engagements?.contentPieces),
            icon: contentPieces,
        },
        {
            title: "Views",
            value: getShortNumber(engagements?.views),
            icon: views,
        },
        {
            title: "Likes",
            value: getShortNumber(engagements?.likes),
            icon: likes,
        },
        {
            title: "Comments",
            value: getShortNumber(engagements?.comments),
            icon: comments,
        },
        {
            title: "Auto DM Sent",
            value: getShortNumber(engagements?.autoDmSent),
            icon: autoDMSent,
        },
        {
            title: "Engagement Rate",
            value: engagements ? engagements?.engagementRate + "%" : "-",
            icon: engagementRate,
        },
    ];

    useEffect(() => {
        if (
            sessionStorage.getItem("isReloaded") === "true" ||
            associatedBusinesses === null
        ) {
            dispatch(clearOverviewState());
            getAssociatedBusiness();
            sessionStorage.removeItem("isReloaded");
        }
        const handleBeforeUnload = () =>
            sessionStorage.setItem("isReloaded", "true");
        window.addEventListener("beforeunload", handleBeforeUnload);
        window.addEventListener("mousedown", handleClickOutside);

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
            window.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleClickOutside = (event: MouseEvent) => {
        if (
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target as Node)
        ) {
            setIsSortOpen(false);
        }
    };
    return (
        <div className={styles.screen}>
            <SideBar />
            <div
                className={styles.screenContent}
                style={isCollapsed ? { width: "95%" } : {}}
            >
                <div className={styles.topBar}>
                    <div className={styles.headerContainer}>
                        {isCollapsed && (
                            <img
                                className={
                                    styles.sideBarCollapseHandleCollapsed
                                }
                                src={sidebarExpand}
                                alt='hamburger'
                                onClick={() => {
                                    dispatch(setIsCollapsed(!isCollapsed));
                                }}
                            />
                        )}
                        <div
                            className={styles.screenHeader}
                            style={isCollapsed ? { marginLeft: "0.5em" } : {}}
                        >
                            Overview
                        </div>
                    </div>
                    <Logout />
                </div>
                <div className={styles.separator}></div>
                <div className={styles.contentWrapper}>
                    <div className={styles.overallPerformanceContainer}>
                        <div className={styles.overallPerformance}>
                            Overall Performance
                        </div>
                        <div className={styles.onboardedDateContainer}>
                            <div className={styles.onboardedDate}>
                                Onboarded Date:
                            </div>
                            <div
                                className={`${styles.onboardedDate} ${styles.onboardedDateValue} `}
                            >
                                {dateConverter(
                                    associatedBusinesses
                                        ? associatedBusinesses[0]?.createdAt
                                        : "",
                                    dateFormats.longDate
                                )}
                            </div>
                        </div>
                    </div>
                    <div className={styles.dataSummary}>
                        <div className={styles.creators}>
                            <div className={styles.creatorsHeading}>
                                TOTAL CREATORS
                            </div>
                            {creators === null ? (
                                <div className={styles.numberOfCreators}>
                                    <Shimmer />
                                </div>
                            ) : creators.length === 0 ? (
                                <></>
                            ) : (
                                <div className={styles.numberOfCreators}>
                                    {getShortNumber(creators?.length)}
                                </div>
                            )}
                            {creators === null ? (
                                <div className={styles.creatorsList}>
                                    <div className={styles.creatorsList}>
                                        {[0, 0, 0, 0, 0].map(
                                            (e: any, i: number) => {
                                                return (
                                                    <div key={i}>
                                                        <div
                                                            className={
                                                                styles.creatorsListItem
                                                            }
                                                        >
                                                            <div
                                                                className={
                                                                    styles.creatorInfoImage
                                                                }
                                                                style={{
                                                                    display:
                                                                        "hidden",
                                                                }}
                                                            >
                                                                <Shimmer />
                                                            </div>
                                                            <div
                                                                className={
                                                                    styles.creatorInfo
                                                                }
                                                            >
                                                                <div
                                                                    className={
                                                                        styles.creatorName
                                                                    }
                                                                >
                                                                    <Shimmer />
                                                                </div>
                                                                <div
                                                                    className={
                                                                        styles.creatorUsername
                                                                    }
                                                                >
                                                                    <Shimmer />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {i <
                                                            creators?.length -
                                                                1 && (
                                                            <hr
                                                                className={
                                                                    styles.creatorListSeparator
                                                                }
                                                            ></hr>
                                                        )}
                                                    </div>
                                                );
                                            }
                                        )}
                                    </div>
                                </div>
                            ) : creators?.length === 0 ? (
                                <div
                                    className={styles.noDataAvailable}
                                    style={{ margin: "auto" }}
                                >
                                    No Data Available
                                </div>
                            ) : (
                                <div className={styles.creatorsList}>
                                    {creators?.map((e: any, i: number) => {
                                        return (
                                            <div key={i}>
                                                <div
                                                    className={
                                                        styles.creatorsListItem
                                                    }
                                                >
                                                    {e.profileImgUrl ? (
                                                        <img
                                                            className={
                                                                styles.creatorInfoImage
                                                            }
                                                            src={
                                                                e.profileImgUrl
                                                            }
                                                            alt='creator-image'
                                                        />
                                                    ) : (
                                                        <div>
                                                            <NameInitials
                                                                name={e.name}
                                                                size='38px'
                                                                fontSize='1rem'
                                                                userBackgroundIndex={
                                                                    null
                                                                }
                                                            />
                                                        </div>
                                                    )}
                                                    <div
                                                        className={
                                                            styles.creatorInfo
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                styles.creatorName
                                                            }
                                                        >
                                                            {e.name}
                                                        </div>
                                                        <div
                                                            className={
                                                                styles.creatorUsername
                                                            }
                                                        >
                                                            @{e.userName}
                                                        </div>
                                                    </div>
                                                </div>
                                                {i < creators?.length - 1 && (
                                                    <hr
                                                        className={
                                                            styles.creatorListSeparator
                                                        }
                                                    ></hr>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                        <div className={styles.engagements}>
                            <div className={styles.engagementsHeading}>
                                ENGAGEMENTS
                            </div>
                            {engagements?.likes +
                                engagements?.comments +
                                engagements?.shares >
                            0 ? (
                                <div className={styles.engagementValue}>
                                    {getShortNumber(
                                        engagements?.likes +
                                            engagements?.comments +
                                            engagements?.shares
                                    )}
                                </div>
                            ) : engagements?.likes +
                                  engagements?.comments +
                                  engagements?.shares ===
                              0 ? (
                                <></>
                            ) : (
                                <div className={styles.engagementValue}>
                                    <Shimmer />
                                </div>
                            )}
                            {engagements?.likes +
                                engagements?.comments +
                                engagements?.shares ===
                            0 ? (
                                <div
                                    className={styles.noDataAvailable}
                                    style={{ margin: "auto" }}
                                >
                                    No Data Available
                                </div>
                            ) : (
                                <div className={styles.engagementList}>
                                    {engagementsArr?.map((e, i) => {
                                        return (
                                            <div key={i}>
                                                <div
                                                    className={
                                                        styles.egagementListItem
                                                    }
                                                >
                                                    <img
                                                        className={
                                                            styles.engagmentItemIcon
                                                        }
                                                        src={e.icon}
                                                        alt='engagement-icon'
                                                    />
                                                    <div
                                                        className={
                                                            styles.engagementItemTitle
                                                        }
                                                    >
                                                        {e.title}
                                                    </div>
                                                    {e.value !== "-" ? (
                                                        <div
                                                            className={
                                                                styles.engagementItemValue
                                                            }
                                                        >
                                                            {e.value}
                                                        </div>
                                                    ) : (
                                                        <div
                                                            className={
                                                                styles.engagementItemValue
                                                            }
                                                        >
                                                            <Shimmer />
                                                        </div>
                                                    )}
                                                </div>
                                                {i <
                                                    engagementsArr.length -
                                                        1 && (
                                                    <hr
                                                        className={
                                                            styles.engagementSeparator
                                                        }
                                                    ></hr>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                        <div className={styles.productsAndWebsites}>
                            <div className={styles.products}>
                                <div className={styles.sold}>
                                    <img
                                        className={styles.productImg}
                                        src={productsSold}
                                        alt='products-sold'
                                    />
                                    <div className={styles.itemDescription}>
                                        PRODUCTS SOLD
                                    </div>
                                    {stats?.productsSold >= 0 ? (
                                        <div className={styles.itemValue}>
                                            {getShortNumber(
                                                stats?.productsSold
                                            ) === "-"
                                                ? 0
                                                : getShortNumber(
                                                      stats?.productsSold
                                                  )}
                                        </div>
                                    ) : (
                                        <div className={styles.itemValue}>
                                            <Shimmer />
                                        </div>
                                    )}
                                </div>
                                <div className={styles.clicked}>
                                    <img
                                        className={styles.productImg}
                                        src={productsClicked}
                                        alt='products-clicked'
                                    />
                                    <div className={styles.itemDescription}>
                                        PRODUCTS CLICKED
                                    </div>
                                    {stats?.productClicks >= 0 ? (
                                        <div className={styles.itemValue}>
                                            {getShortNumber(
                                                stats?.productClicks
                                            ) === "-"
                                                ? 0
                                                : getShortNumber(
                                                      stats?.productClicks
                                                  )}
                                        </div>
                                    ) : (
                                        <div className={styles.itemValue}>
                                            <Shimmer />
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className={styles.websites}>
                                {
                                    <>
                                        <div
                                            className={
                                                styles.wesbiteVisitDescription
                                            }
                                        >
                                            WEBSITE VISITS
                                        </div>
                                        {stats?.websiteVisits === 0 ? (
                                            <>
                                                <div
                                                    className={
                                                        styles.noDataAvailable
                                                    }
                                                    style={{
                                                        justifyContent:
                                                            "center",
                                                        height: "70%",
                                                    }}
                                                >
                                                    No Data Available
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                {stats?.websiteVisits ? (
                                                    <div
                                                        className={
                                                            styles.websiteVisitValue
                                                        }
                                                    >
                                                        {getShortNumber(
                                                            stats?.websiteVisits
                                                        )}
                                                    </div>
                                                ) : (
                                                    <div
                                                        className={
                                                            styles.websiteVisitValue
                                                        }
                                                    >
                                                        <Shimmer />
                                                    </div>
                                                )}
                                                <img
                                                    className={
                                                        styles.websiteVisits
                                                    }
                                                    src={websiteVisits}
                                                    alt='website-visits'
                                                />
                                            </>
                                        )}
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    {/* <div className={styles.roiWrapper}>
                        <div className={styles.roiLeftWrapper}>
                            <RoiPerCampaign />
                        </div>
                        <div className={styles.roiRightWrapper}>
                            <div className={styles.roiRightTopWrapper}>
                                <OverAllPerformance />
                            </div>
                            <div className={styles.roiRightBottomWrapper}>
                                <CampaignPerformance />
                            </div>
                        </div>
                    </div> */}
                    <div className={styles.creatorPerformanceList}>
                        <div className={styles.creatorPerformanceHeader}>
                            <div
                                className={styles.creatorPerformanceListHeading}
                            >
                                Creator Performance
                            </div>
                            <div
                                className={styles.sortWrapper}
                                ref={dropdownRef}
                            >
                                Sort by:{" "}
                                {overallSortKey ? (
                                    <div
                                        className={styles.sortWrapperKey}
                                        onClick={() => {
                                            setIsSortOpen(!isSortOpen);
                                        }}
                                    >
                                        {
                                            Object.entries(
                                                overviewDataKeyMapping
                                            ).find(
                                                ([key, value]) =>
                                                    value === overallSortKey
                                            )?.[0]
                                        }
                                    </div>
                                ) : (
                                    <div
                                        className={styles.sortWrapperKey}
                                        style={{
                                            width: "6em",
                                            height: "1.5em",
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: "6em",
                                                height: "1.5em",
                                            }}
                                        >
                                            <Shimmer />
                                        </div>
                                    </div>
                                )}
                                {isSortOpen && (
                                    <SortDataModal
                                        setIsSortOpen={setIsSortOpen}
                                        sortDropDownElements={
                                            overviewSortDropDownElements
                                        }
                                        page={"overview"}
                                    />
                                )}
                            </div>
                        </div>
                        <OverviewTable
                            isExpandable={true}
                            overallData={overallOverviewData}
                            userWiseSales={creatorWiseSalesData}
                            tableHeaders={overviewTableHeaders}
                            subTableHeader={overviewSubTableHeader}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Index;
