import { Fragment, useEffect, useState, useRef } from "react";
import styles from "./index.module.css";
import SideBar from "../common/SideBar";
import emptyCampaign from "./../../assets/campaigns/emptyCampaign.gif";
import addCampaign from "./../../assets/campaigns/addCampaign.png";
import { useSelector, useDispatch } from "react-redux";
import {
    clearCampaignsState,
    setIsCreateCampaignModalOpen,
    setIsUploadModalOpen,
    setSelectedFilter,
} from "../../redux/slice/campaignsSlice";
import { setSelectedCampaign } from "../../redux/slice/overviewSlice";
import {
    getCampaigns,
    getTopSectionStats,
    uploadCreators,
    deleteCampaign,
    editExpenseAmount,
    updateAutoDMLimit,
} from "../../apiServices/campaignsService";
import GlobalLoader from "../common/GlobalLoader";
// import youtube from "./../../assets/overview/youtube.png";
import instagram from "./../../assets/overview/instagram.png";
// import selected from "./../../assets/modal/selected.png";
// import unSelected from "./../../assets/modal/unSelected.png";
import backArrow from "./../../assets/campaigns/backArrow.png";
import credits from "./../../assets/campaigns/credits.png";
import views from "./../../assets/campaigns/views.png";
import autoDmSent from "./../../assets/campaigns/autoDmSent.png";
import sales from "./../../assets/campaigns/sales.png";
import { dateConverter, getShortNumber } from "../../utilServices/utils";
import { dateFormats } from "../../enums";
import Tooltip from "./../common/Tooltip";
import likes from "./../../assets/overview/likes.png";
import comments from "./../../assets/overview/comments.png";
import shares from "./../../assets/campaigns/shares.png";
import websiteVisits from "./../../assets/campaigns/websiteVisits.png";
import SemiCircleChart from "./SemiDonutChart";
import CampaignTable from "../common/Tables/campaignTable";
import Logout from "../common/Logout/index";
import { verifyPermission } from "../../utilServices/permissionService";
import NameInitial from "../common/NameInitials/index";
import SortDataModal, { campaignDataKeyMapping } from "../common/sortDataModal";
import {
    campaignSortDropDownElements,
    campaignTableHeader,
} from "../../utilServices/tableUtils";
import downloadIcon from "../../assets/campaigns/downloadIcon.png";
import * as XLSX from "xlsx";
import noCreators from "./../../assets/campaigns/noCreators.gif";
import sidebarExpand from "./../../assets/sideBar/sidebarExpand.png";
import { setIsCollapsed } from "../../redux/slice/sidebarSlice";
import editIcon from "../../assets/campaigns/editIcon.png";
import CampaignCreationForm from "./CampaignCreationForm";
import contextMenu from "./../../assets/campaigns/contextMenu.png";
import deleteIcon from "./../../assets/campaigns/deleteIcon.png";
import useClickOutside from "./../../customHooks/ClickOutside";
import editIcon2 from "./../../assets/campaigns/editIcon2.png";
import uploadIcon from "./../../assets/campaigns/uploadIcon.png";
import UploadCreatorsSheet from "./UploadCreatorsSheet";
import { campaignTypes } from "../../enums";
import { useToast } from "./../../customHooks/ToastContext";
import { setFilteredCampaigns } from "../../redux/slice/campaignsSlice";

interface ExcelRow {
    [key: string]: string | number | boolean | null;
}

const Index = () => {
    const dispatch = useDispatch();
    const { showToast } = useToast();

    const { isCollapsed } = useSelector((state: any) => state.root.sidebar);
    const {
        campaigns,
        isCampaignsLoading,
        topSectionStats,
        websitevisit,
        topProducts,
        totalCliks,
        engagements,
        allCreators,
        overallCampaignData,
        campaignSortKey,
        filteredCampaigns,
        selectedFilter,
    } = useSelector((state: any) => state.root.campaigns);
    const { associatedBusinesses, selectedCampaign } = useSelector(
        (state: any) => state.root.overview
    );

    const [autoDMsLimit, setAutoDMsLimit] = useState();
    const [isSortOpen, setIsSortOpen] = useState(false);
    const [showAutoDMLimitEditor, setShowAutoDMLimitEditor] = useState(false);
    const [width, setWidth] = useState(0);
    const [userExpense, setUserExpense] = useState<{
        [key: string]: number;
    }>({});
    const [campaignId, setCampaignId] = useState("");
    const [selectedROIType, setSelectedROIType] = useState("Finance");
    const [isDisabledUpdateAutoDM, setIsDisabledUpdateAutoDM] = useState(true);

    const dropdownRef = useRef<HTMLDivElement>(null);
    const autoDMEditorRef = useRef<HTMLDivElement>(null);
    const parentRef = useRef<HTMLDivElement | null>(null);
    const editModalRef = useRef<HTMLButtonElement | null>(null);
    const deleteModalWrapperRef = useRef<HTMLImageElement | null>(null);
    const numberRegex = /^\d+(\.\d+)?$/;

    const columnArr = [
        "Campaign Name",
        "Platform",
        "Influencers",
        "Created by",
        "Start Date",
        "End Date",
        "Status",
    ];

    const engagementsArr = [
        {
            title: "Likes",
            value: engagements ? engagements?.likes : 0,
            convertedValue:
                getShortNumber(engagements?.likes) === "-"
                    ? 0
                    : getShortNumber(engagements?.likes),
            icon: likes,
            color: "#E697FF",
        },
        {
            title: "Comments",
            value: engagements ? engagements?.comments : 0,
            convertedValue:
                getShortNumber(engagements?.comments) === "-"
                    ? 0
                    : getShortNumber(engagements?.comments),
            icon: comments,
            color: "#63ABFD",
        },
        {
            title: "Shares",
            value: engagements ? engagements?.shares : 0,
            convertedValue:
                getShortNumber(engagements?.shares) === "-"
                    ? 0
                    : getShortNumber(engagements?.shares),
            icon: shares,
            color: "#FF9F75",
        },
    ];

    const filters = [
        `All (${campaigns?.length ? campaigns?.length : 0})`,
        `Live (${
            campaigns?.length
                ? campaigns?.filter(
                      (e: { status: string }) => e?.status === "LIVE"
                  ).length
                : 0
        })`,
        `Completed (${
            campaigns?.length
                ? campaigns?.filter(
                      (e: { status: string }) => e?.status === "COMPLETED"
                  ).length
                : 0
        })`,
        `Offline (${
            campaigns?.length
                ? campaigns?.filter(
                      (e: { status: string }) => e?.status === "OFFLINE"
                  ).length
                : 0
        })`,
    ];

    const handleAutoDMLimitChange = (e: any) => {
        setAutoDMsLimit(e.target.value);
        if (numberRegex.test(e.target.value)) {
            if (e.target.value >= topSectionStats?.autodm?.totalDmsSent) {
                setIsDisabledUpdateAutoDM(false);
            } else {
                setIsDisabledUpdateAutoDM(true);
            }
        } else {
            setIsDisabledUpdateAutoDM(true);
        }
    };

    const handleAutoDMLimitSubmit = () => {
        if (!isDisabledUpdateAutoDM) {
            setShowAutoDMLimitEditor(false);
            updateAutoDMLimit(
                filteredCampaigns[selectedCampaign]?._id,
                Number(autoDMsLimit)
            );
        }
    };

    useEffect(() => {
        if (
            sessionStorage.getItem("isReloaded") === "true" ||
            campaigns === null
        ) {
            dispatch(clearCampaignsState());
            getCampaigns().then((campaignsData) =>
                dispatch(setFilteredCampaigns(campaignsData))
            );
            sessionStorage.removeItem("isReloaded");
        }
        const handleBeforeUnload = () =>
            sessionStorage.setItem("isReloaded", "true");
        setAutoDMsLimit(
            selectedCampaign
                ? filteredCampaigns[selectedCampaign].autoDmConfig
                      .campaignWiseDmLimit
                : 0
        );
        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, []);

    useEffect(() => {
        if (selectedCampaign === null || selectedCampaign === undefined) return;
        getTopSectionStats(filteredCampaigns[selectedCampaign]?._id);
    }, [selectedCampaign]);

    const consolidatedData = [
        {
            title: "",
            icon: credits,
            value: `${getShortNumber(
                topSectionStats?.roi[selectedROIType.toLowerCase()]
            )} %`,
        },
        {
            title: "Views",
            icon: views,
            value: topSectionStats?.views
                ? getShortNumber(topSectionStats?.views)
                : 0,
        },
        {
            title: "Auto DM Sent",
            icon: autoDmSent,
            optionIcon: editIcon,
            value: topSectionStats?.autodm?.totalDmsSent
                ? getShortNumber(topSectionStats?.autodm?.totalDmsSent)
                : 0,
        },
        {
            title: "Sales",
            icon: sales,
            value: topSectionStats?.sales
                ? getShortNumber(topSectionStats?.sales)
                : 0,
        },
    ];

    const editAutoDmLimitHandler = (e: any) => {
        e.preventDefault();
        setShowAutoDMLimitEditor(!showAutoDMLimitEditor);
    };

    useClickOutside(autoDMEditorRef, () => {
        setShowAutoDMLimitEditor(false);
        setAutoDMsLimit(
            selectedCampaign
                ? filteredCampaigns[selectedCampaign]?.autoDmConfig
                      .campaignWiseDmLimit
                : 0
        );
    });
    useClickOutside(dropdownRef, () => setIsSortOpen(false));
    useClickOutside(deleteModalWrapperRef, () => setShowDeleteModal(false));

    const downloadCampaignData = async () => {
        const engagement_data = [
            ["Brand", associatedBusinesses[0]?.name],
            ["Name of Campaign", filteredCampaigns[selectedCampaign]?.name],
            [
                "Timeline",
                `${filteredCampaigns[selectedCampaign]?.startDate} to ${filteredCampaigns[selectedCampaign]?.endDate}`,
            ],
            [
                "Faym connect credits utilized",
                topSectionStats?.autodm?.allowedAutoDms *
                    topSectionStats?.autodm?.pricePerDm -
                    topSectionStats?.autodm?.totalDmsSent *
                        topSectionStats?.autodm?.pricePerDm,
            ],
            ["Total Views", topSectionStats?.views],
            ["Auto Dm sent", topSectionStats?.autodm?.totalDmsSent],
            ["Total sales (GMV)", topSectionStats?.sales],
            ["Total website visits", websitevisit],
            ["Total likes", engagements?.likes],
            ["Total comments", engagements?.comments],
            ["Total shares", engagements?.shares],
            ["Engagement rate", "-"],
        ];

        const overall_data = [
            [
                "Creators profile",
                "IG content link",
                "YT content link",
                "Views",
                "Likes",
                "Comments",
                "Engagement Rate",
                "Auto DM sent",
                "Conversion rate",
                "Sales",
                "Website visits",
            ],
        ];
        overallCampaignData?.forEach((ele: any) => {
            overall_data.push([
                ele.userName,
                "", // IG content link, optional
                "", // YT content link, optional
                ele.views,
                ele.likes,
                ele.comments,
                ele.engagementRate,
                ele.dms,
                ele.saleConversionRate,
                ele.totalSales,
                ele.websiteVisits,
            ]);
        });

        const spacing_rows = Array(2).fill(["", ""]);

        const combined_data = [
            ...engagement_data,
            ...spacing_rows,
            ...overall_data,
        ];
        const ws = XLSX.utils.aoa_to_sheet(combined_data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Campaign Data");

        const fileName = `${
            filteredCampaigns[selectedCampaign]?.name
        }_${new Date().getTime()}.xlsx`;
        XLSX.writeFile(wb, fileName);
    };
    const handleSampleExcel = () => {
        const sampleData = [
            {
                name: "John Doe",
                faymUserName: "john_doe",
                phoneNumber: "1111166666",
                instagramLink: "https://instagram.com/johndoe",
                youtubeLink: "https://youtube.com/@johndoe",
                campaignType:
                    filteredCampaigns[selectedCampaign]?.campaignType ||
                    "Barter",
                expense: 100,
                productUrls: "https://product1.com, https://product2.com",
            },
        ];

        const headers = [
            "name",
            "faymUserName",
            "phoneNumber",
            "instagramLink",
            "youtubeLink",
            "campaignType",
            "expense",
            "productUrls",
        ];

        const dataToExport = sampleData.map((row) => ({
            name: row["name"] || "",
            faymUserName: row["faymUserName"] || "",
            phoneNumber: row["phoneNumber"] || "",
            instagramLink: row["instagramLink"] || "",
            youtubeLink: row["youtubeLink"] || "",
            campaignType: row["campaignType"] || "",
            expense: row["expense"] !== null ? row["expense"] : "",
            productUrls: row["productUrls"] || "",
        }));

        const ws = XLSX.utils.json_to_sheet(dataToExport, { header: headers });
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, "sample_sheet.xlsx");
    };

    const [excelData, setExcelData] = useState<ExcelRow[]>([]);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [isSendDisabled, setIsSendDisabled] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [deleteModalIndex, setDeleteModalIndex] = useState<Number | null>(
        null
    );
    const [showEditModal, setShowEditModal] = useState<boolean>(false);

    const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (event: ProgressEvent<FileReader>) => {
                const binaryStr = event.target?.result;
                if (typeof binaryStr === "string") {
                    const workbook = XLSX.read(binaryStr, { type: "binary" });
                    const sheetName = workbook.SheetNames[0];
                    const sheet = workbook.Sheets[sheetName];
                    const jsonData: (string | number | boolean | null)[][] =
                        XLSX.utils.sheet_to_json(sheet, {
                            header: 1,
                            raw: true,
                        });

                    if (jsonData.length > 0) {
                        const headers = jsonData[0] as string[];
                        const rows = jsonData.slice(1).map((row) =>
                            headers.reduce((acc, header, index) => {
                                if (header && row[index] !== undefined) {
                                    acc[header] = row[index];
                                } else {
                                    acc[header] = null;
                                }
                                return acc;
                            }, {} as ExcelRow)
                        );

                        setExcelData(rows);
                        dispatch(setIsUploadModalOpen(true));
                        validateData(rows);
                    }
                }
            };
            reader.readAsBinaryString(file);
        }
        e.target.value = "";
    };

    function validateAndFormatPhoneNumber(phoneNumber: string) {
        let cleanedNumber = phoneNumber.replace(/\D/g, "");
        const isValidNumber = cleanedNumber.length >= 10;
        if (!isValidNumber) {
            throw new Error("Invalid phone number");
        }
        if (!cleanedNumber.startsWith("+91")) {
            cleanedNumber = "+91" + cleanedNumber;
        }
        return cleanedNumber;
    }

    function validateUsername(username: string) {
        const regex = /^[a-z0-9_]+$/;
        const isValidLength = username.length >= 3 && username.length <= 20;
        const isValidPattern = regex.test(username);
        if (!isValidLength || !isValidPattern) {
            throw new Error(
                "Username must be between 3 and 20 characters long and can only contain lowercase letters, numbers, and underscores."
            );
        }
        return true;
    }

    function validateInstagramURL(url: string): boolean {
        const instagramRegex =
            /^https:\/\/(www\.)?instagram\.com\/[a-zA-Z0-9_.]+\/?$/;
        return instagramRegex.test(url);
    }

    function validateYouTubeURL(url: string): boolean {
        const youtubeRegex =
            /^(https:\/\/)?(www\.)?(youtube\.com\/(c\/|channel\/|user\/|watch\?v=|shorts\/|@)[a-zA-Z0-9_-]+|youtu\.be\/[a-zA-Z0-9_-]+)$/;
        return youtubeRegex.test(url);
    }

    const DeleteComponent = (campaign: any) => {
        const fireDeleteCampaign = async (campaignId: any) => {
            const response = await deleteCampaign(campaignId);
            if (response) {
                showToast("Campaign Deleted Successfully.", "success");
            } else {
                showToast(
                    "You are not allowed to delete this campaign.",
                    "error"
                );
            }
        };
        return (
            <>
                <div
                    className={`${styles.deleteWrapper}`}
                    ref={deleteModalWrapperRef}
                >
                    <button
                        onClick={(e) => {
                            e.stopPropagation();
                            if (verifyPermission("campaign")) {
                                fireDeleteCampaign(campaign.campaign._id);
                            } else {
                                showToast(
                                    "You are not allowed to delete this campaign.",
                                    "error"
                                );
                            }
                            setShowDeleteModal(!showDeleteModal);
                        }}
                        className={`${styles.deleteButton}`}
                    >
                        <img
                            className={`${styles.deleteIcon}`}
                            src={deleteIcon}
                            alt='delete icon'
                        />
                        Delete campaign
                    </button>
                </div>
            </>
        );
    };

    const EditExpenseComponent = () => {
        const fireEditExpenseAmount = async (
            userExpense: any,
            campaignId: any
        ) => {
            const response = await editExpenseAmount(userExpense, campaignId);
            if (response) {
                showToast("Expense Amount updated.", "success");
                setCampaignId("");
                setUserExpense({});
            } else {
                showToast("Unable to update, expense amount", "error");
            }
        };
        return (
            <>
                <div
                    className={`${styles.editExpenceWrapper} ${showEditModal && styles.editModalAnimation}`}
                >
                    <button
                        className={`${styles.editExpenceButton} ${styles.editExpenceButtonCancel}`}
                        onClick={() => {
                            setShowEditModal(false);
                            setCampaignId("");
                            setUserExpense({});
                        }}
                    >
                        Cancel
                    </button>
                    <button
                        className={`${styles.editExpenceButton} ${styles.editExpenceButtonUpdate}`}
                        onClick={() => {
                            fireEditExpenseAmount(userExpense, campaignId);
                            setShowEditModal(false);
                        }}
                    >
                        Update
                    </button>
                </div>
            </>
        );
    };

    const validateData = (rows: ExcelRow[]) => {
        try {
            let hasError = false;
            rows.forEach((row) => {
                try {
                    const selectedCampaignType =
                        filteredCampaigns[selectedCampaign]?.campaignType;
                    const phone = String(row["phoneNumber"]);
                    const username = row["faymUserName"] as string;
                    const instagram = row["instagramLink"] as string;
                    const youtube = row["youtubeLink"] as string;
                    const expense = row["expense"];
                    const campaignsType = row["campaignType"] as string;
                    if (!phone || !validateAndFormatPhoneNumber(phone)) {
                        throw new Error("Invalid phone number.");
                    }
                    if (
                        selectedCampaignType === campaignTypes.Mixed &&
                        ![
                            campaignTypes.Barter,
                            campaignTypes.Paid,
                            campaignTypes.UGC,
                        ].includes(campaignsType)
                    ) {
                        throw new Error(
                            "In Mixed Campaign, CampaignType is Mandatory and must be Barter, Paid, or UGC."
                        );
                    }
                    if (
                        expense !== null &&
                        (typeof expense !== "number" || expense < 0)
                    ) {
                        throw new Error(
                            "Invalid expense value. It must be a non-negative number."
                        );
                    }
                    if (!username || !validateUsername(username)) {
                        throw new Error("Invalid username.");
                    }
                    if (!instagram && !youtube) {
                        throw new Error(
                            "At least one social media link (Instagram or YouTube) must be provided."
                        );
                    }
                    if (instagram && !validateInstagramURL(instagram)) {
                        throw new Error("Invalid Instagram URL.");
                    }
                    if (youtube && !validateYouTubeURL(youtube)) {
                        throw new Error("Invalid YouTube URL.");
                    }
                } catch (error) {
                    hasError = true;
                    setErrorMessage((error as Error).message);
                }
            });

            setIsSendDisabled(hasError);
        } catch (error) {
            console.error("Validation Error:", error);
            setIsSendDisabled(true);
        }
    };

    useEffect(() => {
        // Function to update width
        const updateWidth = () => {
            if (parentRef.current) {
                setWidth(parentRef.current.offsetWidth); // Get the parent's width
            }
        };

        updateWidth(); // Initial width update
        window.addEventListener("resize", updateWidth); // Update width on window resize

        return () => {
            window.removeEventListener("resize", updateWidth); // Cleanup on unmount
        };
    }, []);

    const CAMPAIGN_TAG_BACKGROUND: { [key: string]: string } = {
        barter: "#F2E2F3",
        paid: "#FFF3C8",
        ugc: "#FFD2CB",
    };

    const handelExpenceChange = (event: any, user: string, e: any) => {
        setCampaignId(e.campaignId);
        setUserExpense((prevState) => {
            const obj = { ...prevState };
            obj[user] = event.target.value;
            return obj;
        });
    };

    const renderExpenseAmount = (data: any) => {
        let amount = 0;
        data?.forEach((ele: any) => {
            amount += parseInt(ele.value);
        });
        return amount;
    };

    return (
        <>
            <div className={styles.screen}>
                {isCampaignsLoading && <GlobalLoader />}

                <SideBar />
                <div
                    className={styles.screenContent}
                    style={isCollapsed ? { width: "95%" } : {}}
                >
                    {selectedCampaign != null && filteredCampaigns ? (
                        <>
                            <div className={styles.campaignHeader}>
                                <div className={styles.campaignHeaderWrapper}>
                                    {isCollapsed && (
                                        <img
                                            className={
                                                styles.sideBarCollapseHandleCollapsed
                                            }
                                            src={sidebarExpand}
                                            alt='hamburger'
                                            onClick={() => {
                                                dispatch(
                                                    setIsCollapsed(!isCollapsed)
                                                );
                                            }}
                                        />
                                    )}
                                    <img
                                        className={styles.backArrow}
                                        src={backArrow}
                                        alt='back'
                                        onClick={() => {
                                            dispatch(setSelectedCampaign(null));
                                        }}
                                    />
                                    <div
                                        className={styles.campaignDetailsHeader}
                                    >
                                        <div
                                            className={
                                                styles.campaignNameHeader
                                            }
                                        >
                                            {
                                                filteredCampaigns[
                                                    selectedCampaign
                                                ]?.name
                                            }
                                            <Tooltip
                                                info={
                                                    filteredCampaigns[
                                                        selectedCampaign
                                                    ]?.name
                                                }
                                            />
                                        </div>
                                        <div
                                            className={
                                                styles.campaignDatesHeader
                                            }
                                        >
                                            {dateConverter(
                                                filteredCampaigns[
                                                    selectedCampaign
                                                ]?.startDate,
                                                dateFormats.ddMmYy
                                            )}{" "}
                                            to{" "}
                                            {dateConverter(
                                                filteredCampaigns[
                                                    selectedCampaign
                                                ]?.endDate,
                                                dateFormats.ddMmYy
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.campaignRightWrapper}>
                                    {verifyPermission("download_report") && (
                                        <button
                                            className={styles.downloadBtn}
                                            onClick={downloadCampaignData}
                                        >
                                            Download Report
                                            <img
                                                src={downloadIcon}
                                                alt='download'
                                            />
                                        </button>
                                    )}
                                    <Logout />
                                </div>
                            </div>
                            <div className={styles.separator}></div>
                            <div className={styles.contentWrapper}>
                                <div
                                    className={styles.consolidatedInfoContainer}
                                >
                                    {consolidatedData?.map((e, i) => {
                                        return (
                                            <div
                                                ref={parentRef}
                                                className={
                                                    styles.consolidatedInfoItem
                                                }
                                                key={i}
                                            >
                                                {e.optionIcon &&
                                                    showAutoDMLimitEditor && (
                                                        <div
                                                            className={
                                                                styles.autoDMLimitDialog
                                                            }
                                                            style={{
                                                                width: `${width}px`,
                                                            }}
                                                            ref={
                                                                autoDMEditorRef
                                                            }
                                                        >
                                                            <div
                                                                className={
                                                                    styles.inputHeading
                                                                }
                                                            >
                                                                DMs Sent
                                                            </div>
                                                            <div
                                                                className={
                                                                    styles.inputBox
                                                                }
                                                            >
                                                                {topSectionStats?.autodm?.totalDmsSent.toLocaleString()}
                                                            </div>
                                                            <div
                                                                className={
                                                                    styles.inputHeading
                                                                }
                                                            >
                                                                DMs' Limit
                                                            </div>
                                                            <input
                                                                className={
                                                                    styles.inputBox
                                                                }
                                                                placeholder='Limit DMs'
                                                                value={
                                                                    autoDMsLimit
                                                                }
                                                                onChange={
                                                                    handleAutoDMLimitChange
                                                                }
                                                            />
                                                            <div
                                                                className={
                                                                    styles.submitAutoDMLimit
                                                                }
                                                                style={
                                                                    isDisabledUpdateAutoDM
                                                                        ? {
                                                                              background:
                                                                                  "#E3E2E7",
                                                                              color: "#77767B",
                                                                          }
                                                                        : {
                                                                              cursor: "pointer",
                                                                          }
                                                                }
                                                                onClick={
                                                                    handleAutoDMLimitSubmit
                                                                }
                                                            >
                                                                Update
                                                            </div>
                                                            <div
                                                                className={
                                                                    styles.tip
                                                                }
                                                            >
                                                                Tip: Keep 'DM
                                                                Limit' higher
                                                                than 'DMs Sent.'
                                                            </div>
                                                        </div>
                                                    )}
                                                <div
                                                    className={
                                                        styles.consolidatedInfoItemWrapper
                                                    }
                                                >
                                                    {!e.title && (
                                                        <select
                                                            id='dropdown'
                                                            className={`${styles.roiDropdown} ${styles.consolidatedInfoTitle}`}
                                                            value={
                                                                selectedROIType
                                                            }
                                                            onChange={(e) =>
                                                                setSelectedROIType(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        >
                                                            <option
                                                                value='Finance'
                                                                className={
                                                                    styles.roiOption
                                                                }
                                                            >
                                                                Finance
                                                            </option>
                                                            <option
                                                                value='Engagement'
                                                                className={
                                                                    styles.roiOption
                                                                }
                                                            >
                                                                Engagement
                                                            </option>
                                                        </select>
                                                    )}
                                                    <div
                                                        className={
                                                            styles.consolidatedInfoTitle
                                                        }
                                                    >
                                                        {e.title && e.title}
                                                        {e.optionIcon && (
                                                            <img
                                                                className={
                                                                    styles.editIcon
                                                                }
                                                                src={
                                                                    e.optionIcon
                                                                }
                                                                alt='edit'
                                                                onClick={
                                                                    editAutoDmLimitHandler
                                                                }
                                                            />
                                                        )}
                                                    </div>
                                                    <div
                                                        className={
                                                            styles.roiContainer
                                                        }
                                                    >
                                                        {!e.title && (
                                                            <div
                                                                className={
                                                                    styles.roiText
                                                                }
                                                            >
                                                                ROI:{" "}
                                                            </div>
                                                        )}
                                                        <div
                                                            className={
                                                                styles.consolidatedInfoValue
                                                            }
                                                        >
                                                            {e.value}
                                                        </div>
                                                    </div>
                                                </div>
                                                <img
                                                    className={
                                                        styles.consolidateInfoIcon
                                                    }
                                                    src={e.icon}
                                                    alt={e.title}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                                <div
                                    className={styles.consolidatedInfoContainer}
                                >
                                    <div className={styles.creators}>
                                        <div className={styles.creatorsHeading}>
                                            INFLUENCERS LIVE
                                        </div>
                                        {allCreators?.length > 0 ? (
                                            <>
                                                <div
                                                    className={
                                                        styles.valueAndUpload
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles.numberOfCreators
                                                        }
                                                    >
                                                        {getShortNumber(
                                                            allCreators?.length
                                                        )}
                                                    </div>
                                                    <div
                                                        className={
                                                            styles.actionWrapper
                                                        }
                                                    >
                                                        {verifyPermission(
                                                            "campaign"
                                                        ) && (
                                                            <button
                                                                className={
                                                                    styles.uploadCreatorButton
                                                                }
                                                                onClick={() =>
                                                                    verifyPermission(
                                                                        "campaign"
                                                                    )
                                                                        ? document
                                                                              .getElementById(
                                                                                  "fileInput"
                                                                              )
                                                                              ?.click()
                                                                        : showToast(
                                                                              "You are not allowed to upload.",
                                                                              "error"
                                                                          )
                                                                }
                                                            >
                                                                <img
                                                                    src={
                                                                        uploadIcon
                                                                    }
                                                                    alt='upload'
                                                                />
                                                                Upload
                                                            </button>
                                                        )}
                                                        {verifyPermission(
                                                            "campaign"
                                                        ) && (
                                                            <button
                                                                className={
                                                                    styles.editButton
                                                                }
                                                                onClick={() => {
                                                                    verifyPermission(
                                                                        "campaign"
                                                                    )
                                                                        ? setShowEditModal(
                                                                              !showEditModal
                                                                          )
                                                                        : showToast(
                                                                              "Action not allowed",
                                                                              "error"
                                                                          );
                                                                }}
                                                                ref={
                                                                    editModalRef
                                                                }
                                                            >
                                                                <img
                                                                    src={
                                                                        editIcon2
                                                                    }
                                                                    alt='edit icon'
                                                                />
                                                            </button>
                                                        )}
                                                    </div>
                                                    <input
                                                        type='file'
                                                        id='fileInput'
                                                        style={{
                                                            display: "none",
                                                        }}
                                                        accept='.xls,.xlsx'
                                                        onChange={
                                                            handleFileUpload
                                                        }
                                                    />
                                                </div>
                                                <div
                                                    className={
                                                        styles.detailHeading
                                                    }
                                                >
                                                    <h5
                                                        className={
                                                            styles.detailHeadingText
                                                        }
                                                    >
                                                        Creator Details
                                                    </h5>
                                                    <h5
                                                        className={
                                                            styles.detailHeadingText
                                                        }
                                                    >
                                                        Expense Amount
                                                    </h5>
                                                </div>
                                                <div
                                                    className={
                                                        styles.creatorsList
                                                    }
                                                >
                                                    {allCreators?.map(
                                                        (e: any, i: number) => {
                                                            return (
                                                                <div key={i}>
                                                                    <div
                                                                        className={
                                                                            styles.creatorsListItem
                                                                        }
                                                                    >
                                                                        <div
                                                                            className={
                                                                                styles.creatorLeftWrapper
                                                                            }
                                                                        >
                                                                            {e.profileImgUrl !==
                                                                                "" &&
                                                                            e.profileImgUrl ? (
                                                                                <img
                                                                                    className={
                                                                                        styles.creatorInfoImage
                                                                                    }
                                                                                    src={
                                                                                        e.profileImgUrl
                                                                                    }
                                                                                    alt='creator-image'
                                                                                />
                                                                            ) : (
                                                                                <NameInitial
                                                                                    name={
                                                                                        e.name
                                                                                    }
                                                                                    size={
                                                                                        "2.375em"
                                                                                    }
                                                                                    fontSize={
                                                                                        "1rem"
                                                                                    }
                                                                                    userBackgroundIndex={
                                                                                        null
                                                                                    }
                                                                                />
                                                                            )}
                                                                            <div
                                                                                className={
                                                                                    styles.creatorInfo
                                                                                }
                                                                            >
                                                                                <div
                                                                                    className={
                                                                                        styles.creatorName
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        e.name
                                                                                    }
                                                                                </div>
                                                                                <div
                                                                                    className={
                                                                                        styles.creatorUsername
                                                                                    }
                                                                                >
                                                                                    @
                                                                                    {
                                                                                        e.userName
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                className={
                                                                                    styles.campaignTag
                                                                                }
                                                                                style={{
                                                                                    background:
                                                                                        CAMPAIGN_TAG_BACKGROUND[
                                                                                            e.campaignType.toLowerCase()
                                                                                        ],
                                                                                }}
                                                                            >
                                                                                {e?.campaignType?.toLowerCase()}
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            className={
                                                                                styles.expenceAmountWrapper
                                                                            }
                                                                        >
                                                                            {showEditModal ? (
                                                                                <>
                                                                                    <input
                                                                                        className={
                                                                                            styles.expenceAmountInput
                                                                                        }
                                                                                        type='number'
                                                                                        value={
                                                                                            userExpense[
                                                                                                e
                                                                                                    ?._id
                                                                                            ]
                                                                                                ? userExpense[
                                                                                                      e
                                                                                                          ?._id
                                                                                                  ]
                                                                                                : renderExpenseAmount(
                                                                                                      e?.expense
                                                                                                  )
                                                                                        }
                                                                                        onChange={(
                                                                                            event
                                                                                        ) =>
                                                                                            handelExpenceChange(
                                                                                                event,
                                                                                                e._id,
                                                                                                e
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </>
                                                                            ) : (
                                                                                `₹${renderExpenseAmount(
                                                                                    e?.expense
                                                                                ).toLocaleString()}`
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    {i <
                                                                        allCreators.length -
                                                                            1 && (
                                                                        <hr
                                                                            className={
                                                                                styles.creatorListSeparator
                                                                            }
                                                                        ></hr>
                                                                    )}
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                                </div>
                                            </>
                                        ) : (
                                            <div
                                                className={
                                                    styles.noCreatorsContainer
                                                }
                                            >
                                                <img
                                                    className={
                                                        styles.noCreatorsGif
                                                    }
                                                    src={noCreators}
                                                    alt='0'
                                                />
                                                <div
                                                    className={
                                                        styles.noDataAvailable
                                                    }
                                                    style={{
                                                        color: "black",
                                                    }}
                                                >
                                                    No Data Available
                                                </div>
                                                {verifyPermission(
                                                    "campaign"
                                                ) && (
                                                    <>
                                                        <div
                                                            className={
                                                                styles.uploadCreatorsSheet
                                                            }
                                                            onClick={() =>
                                                                document
                                                                    .getElementById(
                                                                        "fileInput"
                                                                    )
                                                                    ?.click()
                                                            }
                                                        >
                                                            Upload Creator's
                                                            Sheet
                                                        </div>
                                                        <input
                                                            type='file'
                                                            id='fileInput'
                                                            style={{
                                                                display: "none",
                                                            }}
                                                            accept='.xls,.xlsx'
                                                            onChange={
                                                                handleFileUpload
                                                            }
                                                        />
                                                        <div
                                                            className={`${styles.downloadSheetButton}`}
                                                            onClick={() => {
                                                                handleSampleExcel();
                                                            }}
                                                        >
                                                            Download Sample
                                                            Sheet
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        )}
                                        {showEditModal && (
                                            <EditExpenseComponent />
                                        )}
                                    </div>
                                    <div className={styles.engagements}>
                                        <div
                                            className={
                                                styles.engagementsHeading
                                            }
                                        >
                                            ENGAGEMENTS
                                        </div>
                                        {engagements?.likes +
                                            engagements?.shares +
                                            engagements?.comments >
                                        0 ? (
                                            <>
                                                <div
                                                    className={
                                                        styles.engagementValue
                                                    }
                                                >
                                                    {getShortNumber(
                                                        engagements?.likes +
                                                            engagements?.shares +
                                                            engagements?.comments
                                                    ) === "-"
                                                        ? 0
                                                        : getShortNumber(
                                                              engagements?.likes +
                                                                  engagements?.shares +
                                                                  engagements?.comments
                                                          )}
                                                </div>
                                                <div
                                                    className={
                                                        styles.engagementList
                                                    }
                                                >
                                                    {engagementsArr?.map(
                                                        (e, i) => {
                                                            return (
                                                                <Fragment
                                                                    key={i}
                                                                >
                                                                    <div
                                                                        className={
                                                                            styles.egagementListItem
                                                                        }
                                                                    >
                                                                        <img
                                                                            className={
                                                                                styles.engagmentItemIcon
                                                                            }
                                                                            src={
                                                                                e.icon
                                                                            }
                                                                            alt='engagement-icon'
                                                                        />
                                                                        <div
                                                                            className={
                                                                                styles.engagementItemTitle
                                                                            }
                                                                        >
                                                                            {
                                                                                e.title
                                                                            }
                                                                        </div>
                                                                        <div
                                                                            className={
                                                                                styles.engagementItemValue
                                                                            }
                                                                        >
                                                                            {
                                                                                e.convertedValue
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    {i <
                                                                        engagementsArr?.length -
                                                                            1 && (
                                                                        <hr
                                                                            className={
                                                                                styles.engagementSeparator
                                                                            }
                                                                        ></hr>
                                                                    )}
                                                                </Fragment>
                                                            );
                                                        }
                                                    )}
                                                    <div
                                                        className={
                                                            styles.pieChartContainer
                                                        }
                                                    >
                                                        <SemiCircleChart
                                                            data={
                                                                engagementsArr
                                                            }
                                                        />
                                                    </div>
                                                    <div
                                                        className={
                                                            styles.engagementContainer
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                styles.engagementRate
                                                            }
                                                        >
                                                            Eng. Rate
                                                        </div>
                                                        <div
                                                            className={
                                                                styles.engagementRateValue
                                                            }
                                                        >
                                                            {(
                                                                ((engagements?.likes +
                                                                    engagements?.shares +
                                                                    engagements?.comments) *
                                                                    100) /
                                                                engagements?.views
                                                            ).toFixed(2)}
                                                            %
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <div
                                                className={
                                                    styles.noDataAvailable
                                                }
                                                style={{
                                                    margin: "auto",
                                                    color: "black",
                                                }}
                                            >
                                                No Data Available
                                            </div>
                                        )}
                                    </div>
                                    <div className={styles.wesbitesAndClicks}>
                                        <div className={styles.websites}>
                                            <div
                                                className={
                                                    styles.wesbiteVisitDescription
                                                }
                                            >
                                                WEBSITE VISITS
                                            </div>
                                            {getShortNumber(websitevisit) ===
                                            "-" ? (
                                                <div
                                                    className={
                                                        styles.noDataAvailable
                                                    }
                                                    style={{
                                                        color: "white",
                                                        justifyContent:
                                                            "center",
                                                    }}
                                                >
                                                    No Data Available
                                                </div>
                                            ) : (
                                                <>
                                                    <div
                                                        className={
                                                            styles.websiteVisitValue
                                                        }
                                                    >
                                                        {getShortNumber(
                                                            websitevisit
                                                        )}
                                                    </div>
                                                    <img
                                                        className={
                                                            styles.websiteVisits
                                                        }
                                                        src={websiteVisits}
                                                        alt='website-visits'
                                                    />
                                                </>
                                            )}
                                        </div>
                                        <div className={styles.topProducts}>
                                            <div
                                                className={
                                                    styles.topProductsHeader
                                                }
                                            >
                                                TOP PRODUCTS
                                            </div>
                                            {topProducts?.length > 0 ? (
                                                <>
                                                    <div
                                                        className={
                                                            styles.productsContainer
                                                        }
                                                    >
                                                        {topProducts?.map(
                                                            (
                                                                e: any,
                                                                i: number
                                                            ) => {
                                                                return (
                                                                    <img
                                                                        className={
                                                                            styles.productImage
                                                                        }
                                                                        src={
                                                                            e.productImage
                                                                        }
                                                                        alt='product-image'
                                                                        key={i}
                                                                    />
                                                                );
                                                            }
                                                        )}
                                                    </div>
                                                    <div
                                                        className={
                                                            styles.productClicksContainer
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                styles.topProductsHeader
                                                            }
                                                        >
                                                            Clicks:{" "}
                                                        </div>
                                                        <div
                                                            className={
                                                                styles.productClicks
                                                            }
                                                        >
                                                            {getShortNumber(
                                                                totalCliks
                                                            )}
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <div
                                                    className={
                                                        styles.noDataAvailable
                                                    }
                                                    style={{ margin: "auto" }}
                                                >
                                                    No Data Available
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.table}>
                                    <div className={styles.tableHeading}>
                                        Creator Performance
                                        <div
                                            className={styles.sortWrapper}
                                            ref={dropdownRef}
                                        >
                                            Sort by:{" "}
                                            <span
                                                onClick={() => {
                                                    setIsSortOpen(!isSortOpen);
                                                }}
                                            >
                                                {
                                                    Object.entries(
                                                        campaignDataKeyMapping
                                                    ).find(
                                                        ([key, value]) =>
                                                            value ===
                                                            campaignSortKey
                                                    )?.[0]
                                                }
                                            </span>
                                            {isSortOpen && (
                                                <SortDataModal
                                                    setIsSortOpen={
                                                        setIsSortOpen
                                                    }
                                                    sortDropDownElements={
                                                        campaignSortDropDownElements
                                                    }
                                                    page={"campaign"}
                                                />
                                            )}
                                        </div>
                                    </div>
                                    <CampaignTable
                                        tableHeaders={campaignTableHeader}
                                        overallData={overallCampaignData}
                                    />
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className={styles.header}>
                                <div className={styles.headerContainer}>
                                    {isCollapsed && (
                                        <img
                                            className={
                                                styles.sideBarCollapseHandleCollapsed
                                            }
                                            src={sidebarExpand}
                                            alt='hamburger'
                                            onClick={() => {
                                                dispatch(
                                                    setIsCollapsed(!isCollapsed)
                                                );
                                            }}
                                        />
                                    )}
                                    <div
                                        className={styles.screenHeader}
                                        style={
                                            isCollapsed
                                                ? { marginLeft: "0.5em" }
                                                : {}
                                        }
                                    >
                                        Campaigns
                                    </div>
                                </div>
                                <div className={styles.rightWrapper}>
                                    {campaigns?.length > 0 &&
                                        verifyPermission("campaign") && (
                                            <div
                                                className={
                                                    styles.createCampaign
                                                }
                                                onClick={() => {
                                                    dispatch(
                                                        setIsCreateCampaignModalOpen(
                                                            true
                                                        )
                                                    );
                                                }}
                                            >
                                                <img
                                                    className={
                                                        styles.createCampaignIcon
                                                    }
                                                    src={addCampaign}
                                                    alt='create'
                                                />
                                                <div
                                                    className={
                                                        styles.createCampaignText
                                                    }
                                                >
                                                    Create Campaign
                                                </div>
                                            </div>
                                        )}
                                    <Logout />
                                </div>
                            </div>
                            <div className={styles.separator}></div>
                            {campaigns?.length === 0 ? (
                                <div className={styles.gifContainer}>
                                    <img
                                        className={styles.emptyCampaignGif}
                                        src={emptyCampaign}
                                        alt='rocket'
                                    />
                                    <div className={styles.gifHeader}>
                                        No Active Campaigns
                                    </div>
                                    <div className={styles.gifDescription}>
                                        Create your first campaign and track
                                        results in real time!
                                    </div>
                                    {verifyPermission("campaign_users") && (
                                        <div
                                            className={styles.createCampaign}
                                            onClick={() => {
                                                dispatch(
                                                    setIsCreateCampaignModalOpen(
                                                        true
                                                    )
                                                );
                                            }}
                                        >
                                            <img
                                                className={
                                                    styles.createCampaignIcon
                                                }
                                                src={addCampaign}
                                                alt='create'
                                            />
                                            <div
                                                className={
                                                    styles.createCampaignText
                                                }
                                            >
                                                Create Campaign
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <>
                                    <div className={styles.filterBox}>
                                        {filters?.map((e, i) => {
                                            return (
                                                <div
                                                    key={i}
                                                    className={`${
                                                        styles.filterItem
                                                    } ${
                                                        selectedFilter === i &&
                                                        styles.filterItemSelected
                                                    }`}
                                                    onClick={() => {
                                                        dispatch(
                                                            setSelectedFilter(i)
                                                        );
                                                        if (i > 0) {
                                                            dispatch(
                                                                setFilteredCampaigns(
                                                                    campaigns.filter(
                                                                        (
                                                                            camp: any
                                                                        ) =>
                                                                            camp.status ===
                                                                            e
                                                                                .split(
                                                                                    " "
                                                                                )[0]
                                                                                .toUpperCase()
                                                                    )
                                                                )
                                                            );
                                                        } else {
                                                            dispatch(
                                                                setFilteredCampaigns(
                                                                    campaigns
                                                                )
                                                            );
                                                        }
                                                    }}
                                                >
                                                    {e}
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <div className={styles.tableWrapper}>
                                        <div className={styles.tableContainer}>
                                            <table>
                                                <tr
                                                    className={
                                                        styles.campaignListColumns
                                                    }
                                                >
                                                    {columnArr?.map((e, i) => {
                                                        return (
                                                            <th
                                                                key={i}
                                                                className={
                                                                    styles.columnName
                                                                }
                                                            >
                                                                {e}
                                                            </th>
                                                        );
                                                    })}
                                                </tr>
                                                {(selectedFilter === 0
                                                    ? campaigns
                                                    : filteredCampaigns
                                                )?.map((e: any, i: number) => {
                                                    return (
                                                        <Fragment key={i}>
                                                            <tr
                                                                className={
                                                                    styles.campaignListItem
                                                                }
                                                                onClick={() => {
                                                                    dispatch(
                                                                        setSelectedCampaign(
                                                                            i
                                                                        )
                                                                    );
                                                                }}
                                                            >
                                                                <td
                                                                    className={
                                                                        styles.tableData
                                                                    }
                                                                >
                                                                    <div
                                                                        className={
                                                                            styles.campaignName
                                                                        }
                                                                    >
                                                                        {
                                                                            e?.name
                                                                        }
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <img
                                                                        className={
                                                                            styles.socialMediaImage
                                                                        }
                                                                        src={
                                                                            instagram
                                                                        }
                                                                        alt='instagram'
                                                                    />
                                                                    {/* <img
                                                                    className={
                                                                        styles.socialMediaImage
                                                                    }
                                                                    src={
                                                                        youtube
                                                                    }
                                                                    alt='youtube'
                                                                /> */}
                                                                </td>
                                                                <td
                                                                    className={
                                                                        styles.tableData
                                                                    }
                                                                >
                                                                    {
                                                                        e?.creators
                                                                    }
                                                                </td>
                                                                <td
                                                                    className={
                                                                        styles.tableData
                                                                    }
                                                                >
                                                                    {
                                                                        e?.createdBy
                                                                    }
                                                                </td>
                                                                <td
                                                                    className={
                                                                        styles.tableData
                                                                    }
                                                                >
                                                                    {dateConverter(
                                                                        e?.startDate,
                                                                        dateFormats.ddMmYy
                                                                    )}
                                                                </td>
                                                                <td
                                                                    className={
                                                                        styles.tableData
                                                                    }
                                                                >
                                                                    {dateConverter(
                                                                        e?.endDate,
                                                                        dateFormats.ddMmYy
                                                                    )}
                                                                </td>
                                                                <td
                                                                    className={`${styles.lastColumn}`}
                                                                >
                                                                    <div
                                                                        className={`${
                                                                            styles.tableData
                                                                        } ${
                                                                            e?.status ===
                                                                                "LIVE" &&
                                                                            styles.campaignStatus
                                                                        }`}
                                                                    >
                                                                        {
                                                                            e?.status
                                                                        }
                                                                    </div>
                                                                    {verifyPermission(
                                                                        "campaign"
                                                                    ) && (
                                                                        <img
                                                                            ref={
                                                                                deleteModalWrapperRef
                                                                            }
                                                                            className={`${styles.contextMenuImage}`}
                                                                            src={
                                                                                contextMenu
                                                                            }
                                                                            alt='context menu'
                                                                            onClick={(
                                                                                event
                                                                            ) => {
                                                                                event.stopPropagation();
                                                                                setShowDeleteModal(
                                                                                    !showDeleteModal
                                                                                );
                                                                                setDeleteModalIndex(
                                                                                    i
                                                                                );
                                                                            }}
                                                                        />
                                                                    )}
                                                                    {showDeleteModal &&
                                                                        verifyPermission(
                                                                            "campaign"
                                                                        ) &&
                                                                        deleteModalIndex ===
                                                                            i && (
                                                                            <DeleteComponent
                                                                                campaign={
                                                                                    e
                                                                                }
                                                                            />
                                                                        )}
                                                                </td>
                                                            </tr>
                                                            {i <
                                                                filteredCampaigns?.length -
                                                                    1 && (
                                                                <tr>
                                                                    {columnArr.map(
                                                                        (
                                                                            e,
                                                                            i
                                                                        ) => {
                                                                            return (
                                                                                <td
                                                                                    className={
                                                                                        styles.creatorsListSeparatorContainer
                                                                                    }
                                                                                    key={
                                                                                        i
                                                                                    }
                                                                                >
                                                                                    <hr
                                                                                        className={
                                                                                            styles.creatorsListSeparator
                                                                                        }
                                                                                    ></hr>
                                                                                </td>
                                                                            );
                                                                        }
                                                                    )}
                                                                </tr>
                                                            )}
                                                        </Fragment>
                                                    );
                                                })}
                                            </table>
                                        </div>
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </div>
            </div>
            <CampaignCreationForm filters={filters} />
            <UploadCreatorsSheet
                excelData={excelData}
                setExcelData={setExcelData}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
                isSendDisabled={isSendDisabled}
                setIsSendDisabled={setIsSendDisabled}
            />
        </>
    );
};

export default Index;
