import React, {
    createContext,
    useContext,
    useState,
    ReactNode,
    useEffect,
} from "react";
import Toast from "./../components/common/Toasts";

interface ToastContextProps {
    showToast: (message: string, type: string, duration?: number) => void;
}

const ToastContext = createContext<ToastContextProps | undefined>(undefined);

export const useToast = () => {
    const context = useContext(ToastContext);
    if (!context) {
        throw new Error("useToast must be used within a ToastProvider");
    }
    return context;
};

export const ToastProvider: React.FC<{ children: ReactNode }> = ({
    children,
}) => {
    const [toast, setToast] = useState<{
        message: string;
        type: string;
        duration: number;
    } | null>(null);

    const showToast = (
        message: string,
        type: string,
        duration: number = 5000
    ) => {
        setToast({ message, type, duration });
    };

    const handleClose = () => {
        setToast(null);
    };

    useEffect(() => {
        // Attach the showToast function to the window object to make it globally accessible
        window.showToast = showToast;
    }, []);

    return (
        <ToastContext.Provider value={{ showToast }}>
            {children}
            {toast && (
                <Toast
                    message={toast.message}
                    duration={toast.duration}
                    onClose={handleClose}
                    type={toast.type}
                />
            )}
        </ToastContext.Provider>
    );
};
