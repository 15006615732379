import {
    setCampaigns,
    setIsCampaignsLoading,
    setTopSectionStats,
    setWebsiteVisit,
    setTopProducts,
    setTotalClicks,
    setEngagements,
    setAllCreators,
    setOverallCampaignData,
    setCampaignSortKey,
    setIsUploadModalOpen,
    setFilteredCampaigns,
} from "../redux/slice/campaignsSlice";
import store from "../redux/store";
import { getAPIData, postAPIData } from "../utilServices/utils";

function authSelect(state: { root: any }) {
    return state.root.auth;
}
function campaignSelect(state: { root: any }) {
    return state.root.campaigns;
}
function overviewSelect(state: { root: any }) {
    return state.root.overview;
}
let userData: {
    user: any;
};
let campaignsData: {
    campaigns: any;
    allCreators: any;
    selectedFilter: any;
};
let overviewData: {
    associatedBusinesses: any;
};
function listener() {
    userData = authSelect(store.getState());
    campaignsData = campaignSelect(store.getState());
    overviewData = overviewSelect(store.getState());
}
store.subscribe(listener);

export const getCampaigns = async () => {
    try {
        store.dispatch(setIsCampaignsLoading(true));
        const campaignsResponse = await getAPIData({
            url: `business/campaigns?businessId=${userData.user.businessId}`,
        });
        if (campaignsResponse.data.code === 200) {
            store.dispatch(setCampaigns(campaignsResponse.data.data));
            store.dispatch(setIsCampaignsLoading(false));
        }
        return campaignsResponse.data.data;
    } catch (error: any) {
        console.error(error.message);
        store.dispatch(setIsCampaignsLoading(false));
    } finally {
        store.dispatch(setIsCampaignsLoading(false));
    }
};

export const createCampaign = async (campaign: any, filters: any) => {
    try {
        store.dispatch(setIsCampaignsLoading(true));
        const campaignsResponse = await postAPIData(
            {
                url: "business/campaigns/add",
                data: {
                    data: {
                        ...campaign,
                    },
                },
            },
            true
        );
        const campaignsCopy = campaignsData.campaigns.map((e: any) => e);
        if (campaignsResponse.data.code === 200) {
            store.dispatch(setIsCampaignsLoading(false));
            store.dispatch(
                setCampaigns([campaignsResponse.data.data, ...campaignsCopy])
            );
            store.dispatch(
                setFilteredCampaigns(
                    campaignsData.selectedFilter === 0
                        ? [campaignsResponse.data.data, ...campaignsCopy]
                        : [
                              campaignsResponse.data.data,
                              ...campaignsCopy,
                          ].filter(
                              (e: any) =>
                                  e.status ===
                                  filters[campaignsData.selectedFilter]
                                      .split(" ")[0]
                                      .toUpperCase()
                          )
                )
            );
            window.showToast(campaignsResponse.data.message, "success");
        }
        if (campaignsResponse.data.code === 400) {
            window.showToast(campaignsResponse.data.message, "warning");
        }
    } catch (error: any) {
        console.error(error.message);
        store.dispatch(setIsCampaignsLoading(false));
    } finally {
        store.dispatch(setIsCampaignsLoading(false));
    }
};

export const getTopSectionStatsUnchained = async (campaignId: any) => {
    try {
        store.dispatch(setIsCampaignsLoading(true));
        const response = await getAPIData({
            url: `business/campaigns/topStats?campaignId=${campaignId}`,
        });
        if (response.data.code === 200) {
            store.dispatch(setTopSectionStats(response?.data?.data));
        }
    } catch (error: any) {
        console.error(error.message);
    } finally {
        store.dispatch(setIsCampaignsLoading(false));
    }
};

export const getTopSectionStats = async (campaignId: any) => {
    try {
        store.dispatch(setIsCampaignsLoading(true));
        const response = await getAPIData({
            url: `business/campaigns/topStats?campaignId=${campaignId}`,
        });
        if (response.data.code === 200) {
            store.dispatch(setTopSectionStats(response?.data?.data));
            getProductSoldAndWebsiteVisit(campaignId);
            getEngagements(campaignId);
            getAllCreators(campaignId);
            getOverallData(campaignId);
        }
    } catch (error: any) {
        console.error(error.message);
    } finally {
        store.dispatch(setIsCampaignsLoading(false));
    }
};

export const getProductSoldAndWebsiteVisit = async (campaignId: any) => {
    try {
        const response = await getAPIData({
            url: `business/campaigns/topProductsStats?campaignId=${campaignId}`,
        });
        if (response.data.code === 200) {
            store.dispatch(
                setWebsiteVisit(response?.data?.data?.websiteVisits)
            );
            store.dispatch(setTopProducts(response?.data?.data?.topProducts));
            let clicks = 0;
            response?.data?.data?.topProducts?.forEach((ele: any) => {
                clicks += ele.clicks;
            });
            store.dispatch(setTotalClicks(clicks));
        }
    } catch (error: any) {
        console.error(error.message);
    } finally {
    }
};

export const getEngagements = async (campaignId: any) => {
    try {
        const response = await getAPIData({
            url: `business/campaigns/engagement?campaignId=${campaignId}`,
        });
        if (response.data.code === 200) {
            store.dispatch(setEngagements(response?.data?.data));
        }
    } catch (error: any) {
        console.error(error.message);
    } finally {
    }
};

export const getAllCreators = async (campaignId: any) => {
    try {
        const response = await getAPIData({
            url: `business/campaignCreators?campaignId=${campaignId}`,
        });
        if (response.data.code === 200) {
            store.dispatch(setAllCreators(response?.data?.data));
            // store.dispatch(setAllCreators([]));
        }
    } catch (error: any) {
        console.error(error.message);
    } finally {
    }
};

export const getOverallData = async (campaignId: any) => {
    try {
        const response = await getAPIData({
            url: `business/campaigns/overall?campaignId=${campaignId}`,
        });
        if (response.data.code === 200) {
            let data = response?.data?.data?.result;
            data = data?.map((ele: any) => {
                ele?.socials?.forEach((item: any) => {
                    if (ele.cummulativeFollowers) {
                        ele.cummulativeFollowers += item.stats.follower;
                    } else {
                        ele.cummulativeFollowers = item.stats.follower;
                    }
                });
                return ele;
            });
            store.dispatch(setOverallCampaignData(data));
            store.dispatch(setCampaignSortKey(response?.data?.data?.sortKey));
        }
    } catch (error: any) {
        console.error(error.message);
    } finally {
    }
};

export const uploadCreators = async (campaignId: any, formattedData: any) => {
    try {
        const response = await postAPIData(
            {
                url: "business/campaignCreators/add",
                data: {
                    campaignId: campaignId,
                    businessId: overviewData.associatedBusinesses[0]._id,
                    data: formattedData,
                },
            },
            true
        );
        if (response.data.code === 200) {
            store.dispatch(setIsUploadModalOpen(false));
            window.showToast(response.data.message, "success");
            setTimeout(() => {
                window.location.reload();
            }, 5000);
        } else {
            store.dispatch(setIsUploadModalOpen(false));
            window.showToast(response.data.message, "error");
        }
    } catch (error: any) {
        console.error(error.message);
    }
};

export const deleteCampaign = async (campaignId: any) => {
    try {
        store.dispatch(setIsCampaignsLoading(true));
        const response = await postAPIData(
            {
                url: "business/campaigns/delete",
                data: { campaignId: campaignId },
            },
            true
        );
        if (response.data.code === 200) {
            let remainingCampaigns = campaignsData?.campaigns?.filter(
                (ele: any) => ele?._id !== campaignId
            );
            store.dispatch(setCampaigns(remainingCampaigns));
            store.dispatch(setIsCampaignsLoading(false));
            return true;
        }
        return false;
    } catch (error: any) {
        console.error(error.message);
        store.dispatch(setIsCampaignsLoading(false));
        return false;
    } finally {
        store.dispatch(setIsCampaignsLoading(false));
    }
};

export const editExpenseAmount = async (data: any, campaignId: string) => {
    try {
        let reqestData: { expenses: any[]; campaignId: string } = {
            campaignId: campaignId,
            expenses: [],
        };
        for (let user of Object.keys(data)) {
            let obj = {
                creatorId: user,
                expense: [
                    {
                        purpose: "FIXED",
                        value: data[user],
                    },
                ],
            };
            reqestData.expenses.push(obj);
        }
        const response = await postAPIData(
            {
                url: "business/campaignCreators/expenses/update",
                data: reqestData,
            },
            true
        );
        if (response.data.code === 200) {
            let updatedData: any[] = campaignsData?.allCreators.map(
                (ele: any) => {
                    const updatedExpenses = ele?.expense?.map(
                        (expense: any) => ({
                            ...expense,
                            value: data.hasOwnProperty(ele?._id.toString())
                                ? data[ele?._id.toString()]
                                : expense.value,
                        })
                    );
                    return {
                        ...ele,
                        expense: updatedExpenses,
                    };
                }
            );
            store.dispatch(setAllCreators(updatedData));
            return true;
        }
        return false;
    } catch (error: any) {
        console.error(error.message);
    }
};

export const updateAutoDMLimit = async (campaignId: string, limit: number) => {
    try {
        let reqestData: { limit: number; campaignId: string } = {
            campaignId: campaignId,
            limit: limit,
        };
        const response = await postAPIData(
            {
                url: "business/campaigns/autodm-config/update",
                data: reqestData,
            },
            true
        );
        if (response.data.code === 200) {
            getTopSectionStatsUnchained(campaignId);
            return true;
        }
        return false;
    } catch (error: any) {
        console.error(error.message);
    }
};
