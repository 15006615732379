import { useState } from "react";
import Modal from "../../common/Modal";
import {
    setFilteredCampaigns,
    setIsCreateCampaignModalOpen,
} from "../../../redux/slice/campaignsSlice";
import { useSelector, useDispatch } from "react-redux";
import styles from "./index.module.css";
import closeModal from "./../../../assets/modal/closeModal.png";
import { dateConverter } from "../../../utilServices/utils";
import { dateFormats } from "../../../enums";
import { useToast } from "./../../../customHooks/ToastContext";
import { createCampaign } from "../../../apiServices/campaignsService";

const Index = ({ filters }: { filters: any }) => {
    const dispatch = useDispatch();
    const { showToast } = useToast();
    const { user } = useSelector((state: any) => state.root.auth);
    const { isCreateCampaignModalOpen, campaigns, selectedFilter } =
        useSelector((state: any) => state.root.campaigns);
    const { associatedBusinesses } = useSelector(
        (state: any) => state.root.overview
    );

    const date = new Date();
    date.setDate(date.getDate() - 29);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [startDate, setStartDate] = useState(`${year}-${month}-${day}`);
    const [campaign, setCampaign] = useState<any>({
        startDate: startDate,
        deliverables: {},
    });
    const [selectedCampaignType, setSelectedCampaignType] = useState("");
    const [validations, setValidations] = useState({
        name: false,
        description: false,
        campaignType: false,
        startDate: true,
        endDate: false,
        story: false,
        reel: false,
        post: false,
    });

    const isFormValid = Object.values(validations).every((isValid) => isValid);

    const handleValidation = (inputName: string, isValid: boolean) => {
        setValidations((prevState) => ({
            ...prevState,
            [inputName]: isValid,
        }));
    };
    const [endDate, setEndDate] = useState("");

    const handleCreateCampaignModalClose = () => {
        setCampaign({ startDate: startDate });
        dispatch(setIsCreateCampaignModalOpen(false));
        setSelectedCampaignType("");
        setStartDate(`${year}-${month}-${day}`);
        setEndDate("");
        // setIsInstagramChecked(false);
        // setIsYoutubeChecked(false);
        setDescription("");
        setName("");
        setValidations({
            name: false,
            description: false,
            campaignType: false,
            startDate: true,
            endDate: false,
            story: false,
            reel: false,
            post: false,
        });
    };

    const handleCampaignTypeChange = (event: any) => {
        setSelectedCampaignType(event.target.value);
        setCampaign({
            ...campaign,
            campaignType: event.target.value,
        });
        handleValidation("campaignType", event.target.value !== "");
    };

    const handleStartDateChange = (e: any) => {
        setStartDate(e.target.value);
        if (endDate && e.target.value > endDate) {
            setEndDate("");
        }
        setCampaign({
            ...campaign,
            startDate: new Date(e.target.value).toISOString(),
        });
    };

    const handleEndDateChange = (e: any) => {
        setEndDate(e.target.value);
        if (e.target.value !== "") {
            setCampaign({
                ...campaign,
                endDate: new Date(e.target.value).toISOString(),
            });
        }
        // if (startDate! > e.target.value) {
        //     showToast(
        //         "Campaign End Date can not be before the Campaign Start Data",
        //         "error"
        //     );
        // }
        handleValidation("endDate", e.target.value !== "");
    };

    const submitHandler = () => {
        if (startDate > endDate) {
            showToast(
                "Campaign End Date Should always be after the Campaign Start Date",
                "error"
            );
            return;
        }
        createCampaign(campaign, filters);
        handleCreateCampaignModalClose();
    };

    return (
        <Modal
            showModal={isCreateCampaignModalOpen}
            onClose={handleCreateCampaignModalClose}
            alignItems={"center"}
        >
            <div
                className={styles.createCampaignModalContainer}
                onClick={(e) => e.stopPropagation()}
            >
                <div className={styles.modalHeader}>
                    <div className={styles.modalHeading}>
                        Create new campaign
                    </div>
                    <img
                        className={styles.closeModal}
                        src={closeModal}
                        alt='close-modal'
                        onClick={() =>
                            dispatch(setIsCreateCampaignModalOpen(false))
                        }
                    />
                </div>
                <div className={styles.campaignDetails}>
                    <div
                        className={`${styles.inputContainer} ${styles.inputContainerName}`}
                    >
                        <div className={styles.inputLabel}>Campaign Name*</div>
                        <input
                            className={styles.inputBox}
                            placeholder='Name your campaign'
                            value={name}
                            onChange={(e) => {
                                setCampaign({
                                    ...campaign,
                                    name: e.target.value,
                                });
                                setName(e.target.value);
                                handleValidation("name", e.target.value !== "");
                            }}
                            maxLength={40}
                        />
                        <div className={styles.characterCount}>
                            {name.length}/40
                        </div>
                    </div>
                    <div className={styles.descriptionAndPlatform}>
                        <div
                            className={`${styles.inputContainer} ${styles.inputContainerDescription}`}
                        >
                            <div className={styles.inputLabel}>
                                Campaign Description*
                            </div>
                            <textarea
                                className={styles.inputBox}
                                placeholder='Define your campaign'
                                onChange={(e) => {
                                    setDescription(e.target.value);
                                    setCampaign({
                                        ...campaign,
                                        description: e.target.value,
                                    });
                                    handleValidation(
                                        "description",
                                        e.target.value !== ""
                                    );
                                }}
                                maxLength={350}
                            />
                            <div className={styles.characterCount}>
                                {description.length}/350
                            </div>
                        </div>
                        {/* <div className={styles.platformsContainer}>
                                <div className={styles.inputLabel}>
                                    Platforms*
                                </div>
                                <div className={styles.platformsList}>
                                    <div
                                        className={
                                            styles.platformSelectContainer
                                        }
                                    >
                                        <img
                                            className={styles.platformSelect}
                                            src={
                                                isInstagramChecked
                                                    ? selected
                                                    : unSelected
                                            }
                                            onClick={() => {
                                                setCampaign({
                                                    ...campaign,
                                                    platforms: [
                                                        {
                                                            youtube:
                                                                isYoutubeChecked,
                                                            instagram:
                                                                !isInstagramChecked,
                                                        },
                                                    ],
                                                });
                                                setIsInstagramChecked(
                                                    !isInstagramChecked
                                                );
                                            }}
                                            alt='checkbox'
                                        />
                                        <img
                                            className={styles.platformIcon}
                                            src={instagram}
                                            alt='platform'
                                        />
                                    </div>
                                    <div
                                        className={
                                            styles.platformSelectContainer
                                        }
                                    >
                                        <img
                                            className={styles.platformSelect}
                                            src={
                                                isYoutubeChecked
                                                    ? selected
                                                    : unSelected
                                            }
                                            onClick={() => {
                                                setCampaign({
                                                    ...campaign,
                                                    platforms: [
                                                        {
                                                            youtube:
                                                                !isYoutubeChecked,
                                                            instagram:
                                                                isInstagramChecked,
                                                        },
                                                    ],
                                                });
                                                setIsYoutubeChecked(
                                                    !isYoutubeChecked
                                                );
                                            }}
                                            alt='checkbox'
                                        />
                                        <img
                                            className={styles.platformIcon}
                                            src={youtube}
                                            alt='platform'
                                        />
                                    </div>
                                </div>
                            </div> */}
                    </div>
                </div>
                <div className={styles.dateContainer}>
                    <div className={styles.inputContainer}>
                        <div className={styles.inputLabel}>Type*</div>
                        <select
                            className={`${styles.inputBox} ${styles.selectInput}`}
                            id='dropdown'
                            value={selectedCampaignType}
                            onChange={handleCampaignTypeChange}
                        >
                            <option value='' disabled>
                                Choose a campaign type
                            </option>
                            <option value='Barter'>Barter</option>
                            <option value='Paid'>Paid</option>
                            <option value='UGC'>UGC</option>
                            <option value='Mixed'>Mixed</option>
                        </select>
                    </div>
                    <div className={styles.dates}>
                        <div className={styles.inputContainer}>
                            <div className={styles.inputLabel}>Start Date*</div>
                            <input
                                className={styles.inputBox}
                                type='date'
                                id='start-date'
                                value={startDate}
                                onChange={handleStartDateChange}
                                min={dateConverter(
                                    associatedBusinesses
                                        ? associatedBusinesses[0]?.createdAt
                                        : "",
                                    dateFormats.yyyyMmDd
                                )}
                            />
                        </div>
                        <div className={styles.inputContainer}>
                            <div className={styles.inputLabel}>End Date*</div>
                            <input
                                className={styles.inputBox}
                                type='date'
                                id='end-date'
                                value={endDate}
                                onChange={handleEndDateChange}
                                min={startDate}
                            />
                        </div>
                    </div>
                </div>
                {/* <div className={styles.deliverablesContainer}>
                        <div className={styles.inputLabel}>Youtube</div>
                        <div className={styles.deliverables}>
                            <div
                                className={`${styles.deliverableInputBox} ${styles.youtube}`}
                            >
                                <div className={styles.deliverableInputLable}>
                                    Shorts:
                                </div>
                                <input
                                    className={styles.deliverableInput}
                                    onChange={(e) => {
                                        setCampaign({
                                            ...campaign,
                                            deliverables: {
                                                ...campaign.deliverables,
                                                short: e.target.value,
                                            },
                                        });
                                    }}
                                />
                            </div>
                            <div
                                className={`${styles.deliverableInputBox} ${styles.youtube}`}
                            >
                                <div className={styles.deliverableInputLable}>
                                    Videos:
                                </div>
                                <input
                                    className={styles.deliverableInput}
                                    onChange={(e) => {
                                        setCampaign({
                                            ...campaign,
                                            deliverables: {
                                                ...campaign.deliverables,
                                                video: e.target.value,
                                            },
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    </div> */}

                <div className={styles.deliverablesContainer}>
                    <div className={styles.inputLabel}>Deliverables</div>
                    <div className={styles.deliverables}>
                        <div
                            className={`${styles.deliverableInputBox} ${styles.instagram}`}
                        >
                            <div className={styles.deliverableInputLable}>
                                Story:
                            </div>
                            <input
                                className={styles.deliverableInput}
                                onChange={(e) => {
                                    setCampaign({
                                        ...campaign,
                                        deliverables: {
                                            ...campaign.deliverables,
                                            story: e.target.value,
                                        },
                                    });
                                    handleValidation(
                                        "story",
                                        e.target.value !== ""
                                    );
                                }}
                                type='number'
                            />
                        </div>
                        <div
                            className={`${styles.deliverableInputBox} ${styles.instagram}`}
                        >
                            <div className={styles.deliverableInputLable}>
                                Reels:
                            </div>
                            <input
                                className={styles.deliverableInput}
                                onChange={(e) => {
                                    setCampaign({
                                        ...campaign,
                                        deliverables: {
                                            ...campaign.deliverables,
                                            reel: e.target.value,
                                        },
                                    });
                                    handleValidation(
                                        "reel",
                                        e.target.value !== ""
                                    );
                                }}
                                type='number'
                            />
                        </div>
                        <div
                            className={`${styles.deliverableInputBox} ${styles.instagram}`}
                        >
                            <div className={styles.deliverableInputLable}>
                                Posts:
                            </div>
                            <input
                                className={styles.deliverableInput}
                                onChange={(e) => {
                                    setCampaign({
                                        ...campaign,
                                        deliverables: {
                                            ...campaign.deliverables,
                                            post: e.target.value,
                                        },
                                    });
                                    handleValidation(
                                        "post",
                                        e.target.value !== ""
                                    );
                                }}
                                type='number'
                            />
                        </div>
                    </div>
                </div>

                <div
                    className={styles.modalSubmitButton}
                    onClick={isFormValid ? submitHandler : () => {}}
                    style={
                        !isFormValid
                            ? { background: "#E3E2E7", color: "#77767B" }
                            : {}
                    }
                >
                    Create Campaign
                </div>
            </div>
        </Modal>
    );
};

export default Index;
