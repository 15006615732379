import React, { useState } from "react";
import Modal from "../../common/Modal";
import { useSelector, useDispatch } from "react-redux";
import { setIsUploadModalOpen } from "../../../redux/slice/campaignsSlice";
import styles from "./index.module.css";
import closeModal from "./../../../assets/modal/closeModal.png";
import UploadedCreatorsTable from "../../common/Tables/uploadedCreatorsTable";
import { uploadCreators } from "../../../apiServices/campaignsService";

interface ExcelRow {
    [key: string]: string | number | boolean | null;
}

const Index = ({
    excelData,
    setExcelData,
    errorMessage,
    setErrorMessage,
    isSendDisabled,
    setIsSendDisabled,
}: {
    excelData: any;
    setExcelData: any;
    errorMessage: any;
    setErrorMessage:any;
    isSendDisabled: any;
    setIsSendDisabled: any;
}) => {
    const { isUploadModalOpen, filteredCampaigns } = useSelector(
        (state: any) => state.root.campaigns
    );

    const { selectedCampaign } = useSelector(
        (state: any) => state.root.overview
    );

    const dispatch = useDispatch();

    const handleCancel = () => {
        dispatch(setIsUploadModalOpen(false));
        setExcelData([]);
        setErrorMessage(null);
        setIsSendDisabled(false);
    };

    const handleSend = () => {
        if (isSendDisabled) return;

        const formattedData = excelData.map((row: ExcelRow) => {
            const productUrls = row["productUrls"] as string;

            return {
                ...row,
                phoneNumber: String(row["phoneNumber"]),
                campaignType:
                    row["campaignType"] ||
                    (filteredCampaigns[selectedCampaign]?.campaignType !==
                    "Mixed"
                        ? filteredCampaigns[selectedCampaign]?.campaignType
                        : null),
                attachedProducts: productUrls
                    ? productUrls.split(",").map((url) => url.trim())
                    : [],
                expense: [
                    {
                        purpose: "FIXED",
                        value: String(row["expense"]),
                    },
                ],
            };
        });

        uploadCreators(filteredCampaigns[selectedCampaign]._id, formattedData);
        dispatch(setIsUploadModalOpen(false));
    };

    const formatCampaignType = (excelData: ExcelRow[]) => {
        return excelData.map((row: ExcelRow) => ({
            ...row,
            campaignType:
                filteredCampaigns[selectedCampaign]?.campaignType !== "Mixed"
                    ? filteredCampaigns[selectedCampaign]?.campaignType
                    : row["campaignType"],
        }));
    };


    return (
        <Modal
            showModal={isUploadModalOpen}
            onClose={handleCancel}
            alignItems={"center"}
        >
            <div
                className={styles.modalContent}
                onClick={(e) => e.stopPropagation()}
            >
                <div
                    className={styles.modalHeader}
                    style={{
                        width: "100%",
                    }}
                >
                    <div className={styles.modalHeading}>Import Creators</div>
                    <img
                        className={styles.closeModal}
                        src={closeModal}
                        alt='close'
                        onClick={handleCancel}
                    />
                </div>
                <div className={styles.uploadedCreatorsTable}>
                    {excelData.length > 0 && (
                        <UploadedCreatorsTable
                            tableHeaders={[
                                "Creator",
                                "Phone Number",
                                "Instagram",
                                "Youtube",
                                "Expense",
                                "Campaign Type",
                            ]}
                            overallData={formatCampaignType(excelData)}
                        />
                    )}
                </div>
                {errorMessage && (
                    <div className={styles.errorMessage}>{errorMessage}</div>
                )}
                <div className={styles.buttonContainer}>
                    <div onClick={handleCancel} className={styles.cancelButton}>
                        Cancel
                    </div>
                    <div
                        onClick={isSendDisabled ? () => {} : handleSend}
                        className={styles.sendButton}
                        style={
                            isSendDisabled
                                ? {
                                      background: "#E3E2E7",
                                      color: " #77767B",
                                  }
                                : {}
                        }
                    >
                        Confirm
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default Index;
